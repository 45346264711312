<script>
/**
 * @template T
 * @typedef {import('@vue/composition-api').Ref<T>} Ref
 */

/**
 * @typedef {import('@/types/product').ProductFormModel} ProductFormModel
 */
import { defineComponent, nextTick, ref, watch, computed, getCurrentInstance } from 'vue'
import { validate, ValidationProvider } from 'vee-validate';
import { uploadProductImage } from '@/api/product'

const ACCEPTED_EXTENSION = ['jpg', 'jpeg', 'png', 'bmp'];
const MAX_IMAGE = 15;
const MAX_IMAGE_SIZE_IN_KB = 1000; // 10_000
const MIN_WIDTH = 300
const MIN_HEIGHT = 300

/**
 * @param {ProductFormModel['images'][0]} image 
 */
const getImageUrl = (image) => {
  if (image.url) return `${image.url}?tr=w-200,h-200,cm-pad_resize,bg-FFFFFF`
  if (image.file) return URL.createObjectURL(image.file)

  return null
}

export default defineComponent({
  components: {
    ValidationProvider,
  },
  props: {
    value: Object,
    permission: Array,
  },
  setup(props, { emit }) {
    const { $message, $store, $route } = getCurrentInstance().proxy.$root
    /** @type {Ref<ProductFormModel>} */
    const model = ref(props.value)
    watch(model, () => emit('input', model.value))
    watch(() => props.value, () => {
      model.value = props.value
    })

    /** @type {Ref<ProductFormModel['images']>} */
    const images = ref(props.value.images || []);
    let selectedImages = ref([])
    
    watch(() => props.value.images, (value) => {
      images.value = value || [];
    }, { deep: true });

    watch(() => props.value.catalogs, (value) => {
      if (value?.items?.length > 0) {
        let arrImages = []
        value.items?.forEach(item => {
          arrImages = [...arrImages, ...item.images]
        })
        selectedImages.value = arrImages?.map((obj) => obj.refId)
      } else {
        selectedImages.value = []
      }
    }, { deep: true });

    const activeImages = computed(() => images.value.filter(({ deleted }) => !deleted));

    const isCanAddImage = computed(() => images.value.length < MAX_IMAGE && props.permission.includes('WRITE') );

    const validateDimension = (file) => {
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.src = window.URL.createObjectURL(file)
        img.addEventListener('load', e => {
          const { naturalWidth, naturalHeight, src } = e.target
          if (naturalWidth < MIN_WIDTH || naturalHeight < MIN_HEIGHT) {
            reject(file)
          } else {
            resolve(file)
          }
          window.URL.revokeObjectURL(src)
        })
      })
    }

    const compressImage = async (file, minSizeConvert) => {
      const compress = (file, quality) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = (event) => {
            const img = new Image()
            img.src = event.target.result
            img.onload = () => {
              const canvas = document.createElement('canvas')
              const ctx = canvas.getContext('2d')
              canvas.width = img.width
              canvas.height = img.height
              ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
              canvas.toBlob(
                (blob) => {
                  const compressedFile = new File([blob], file.name, { type: blob.type });
                  resolve(compressedFile)
                },
                file.type,
                quality,
              )
            }
          }
          reader.onerror = (error) => reject(error)
        })
      }

      let quality = 0.9
      let compressedFile = file
      while (compressedFile.size > minSizeConvert && quality > 0.1) {
        compressedFile = await compress(file, quality)
        quality -= 0.1
      }
      return compressedFile
    }

    const imageResize = () => {
      images.value.forEach((image, index) => {
        let imageFile = image.file
        var reader = new FileReader()
        reader.onload = function (e) {
          var img = new Image()
          img.onload = function (event) {
            // Dynamically create a canvas element
            var canvas = document.createElement("canvas")

            // var canvas = document.getElementById("canvas");
            var ctx = canvas.getContext("2d")

            // Actual resizing
            canvas.width = 300
            canvas.height = 300
            ctx.drawImage(img, 0, 0, 300, 300)

            // Show resized image in preview element
            var dataurl = canvas.toDataURL(imageFile.type)
            
            var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[arr.length - 1]), 
            n = bstr.length, 
            u8arr = new Uint8Array(n)
            while(n--){
              u8arr[n] = bstr.charCodeAt(n)
            }
            var newImage = new File([u8arr], imageFile.name, {type:mime})
            updateImage(newImage, index)
          }
          img.src = e.target.result
        }
        reader.readAsDataURL(imageFile)
      })
    }

    const updateImage = (image, index) => {
      images.value[index].thumbnail = image
      if(index == (images.value.length - 1)) save()
    }
    
    const onFileChanged = async (e) => {
      // e.preventDefault()
      const files = Array.from(e.target.files)
      const remainingImageCanUpload = MAX_IMAGE - images.value.length

      if (files.length > remainingImageCanUpload) {
        return $message.error(`You can upload maximum ${remainingImageCanUpload} images left`)
      }

      const results = await validateImages(files)

      const invalidImage = results.find(({ result }) => !result.valid)
      if (invalidImage) {
        $message.error(invalidImage.result.errors[0], 5)
        return
      } else {
        await Promise.all(files.map(async f => await validateDimension(f)))
          .then(() => {
            addImages(files)
          })
          .catch(() => {
            $message.error(`Dimensi foto minimal ${MIN_WIDTH}x${MIN_HEIGHT} px`)
            return
          })
      }

      e.target.value = ''
    }

    /**
     * @param {File[]} files 
     * @typedef {{result: import('vee-validate/dist/types/types').ValidationResult, file: File }} ValidationResult
     * @returns {Promise<ValidationResult[]>}
     */
    const validateImages = async (files) => {
      return Promise.all(
        Array.from(files).map(async (file) => {
          const afterCompressImages = await compressImage(file, MAX_IMAGE_SIZE_IN_KB * 1000)
          const result = await validate(
            afterCompressImages,
            [
              `ext:${ACCEPTED_EXTENSION.join(',')}`,
              `size:${MAX_IMAGE_SIZE_IN_KB}`,
            ].join('|'), 
            { name: 'File' },
          )
          return { result, file }
        }),
      )
    }

    const addImages = async (files) => {
      images.value.push(
        ...files.map(file => ({
          file,
          uploading: false,
          failed: null,
          message: null,
          deleted: false,
        })),
      )

      await uploadImages()
    }

    const uploadImage = async (image) => {
      image.uploading = true
      image.failed = null
      
      try {
        const { data } = await uploadProductImage({
          business_id: $route.query.business_id,
          product_id: props.value.id,
          file: image.file,
        })
        // image.file = null // comment this line when product master to channel is available
        image.ref_id = data.fileId
        image.name = data.name
        image.url = data.url
        image.uploading = false
        image.failed = null
        return data
      } catch (err) {
        const maxUpload = 'Maximum upload size exceeded'
        const msg = err?.response?.data?.message || 'Failed'
        image.uploading = false
        image.failed = msg.includes(maxUpload) ? maxUpload : msg
        throw err
      }
    }

    const uploadImages = async (saveAfterUpload = true) => {
      if (!props.value.id) return // bail out when product id is null
      const unuploadedImages = images.value.filter(i => !i.ref_id)
      
      const results = await Promise.all(
        unuploadedImages.map(async (image) => await uploadImage(image)),
      )

      if (results.length && saveAfterUpload) save()
      
      return results
    }

    watch(() => props.value.id, (value) => {
      if (value) nextTick(() => uploadImages())
    })

    const setAsPrimary = (index) => {
      images.value.splice(0, 0, images.value.splice(index, 1)[0]);
      save()
    }
    const setAsThumbnail = (index) => {
      images.value.splice(1, 0, images.value.splice(index, 1)[0]);
      save()
    }
    const deleteImage = (index) => {
      images.value[index].deleted = true
      save()
    }
    const save = () => {
      emit('change', images.value.map((image, index) => ({
        ...image,
        order: index,
        tags: [ 
          index === 0 ? 'SHOW' : undefined,
          index === 1 || images.value.length === 1 ? 'THUMB' : undefined,
        ].filter(Boolean),
      })))
    }

    return {
      model,
      images,
      activeImages,
      selectedImages,
      getImageUrl,
      isCanAddImage,
      onFileChanged,
      ACCEPTED_EXTENSION,
      setAsPrimary,
      setAsThumbnail,
      deleteImage,
      uploadImages,
      minWidth: MIN_WIDTH,
      minHeight: MIN_HEIGHT,
    }
  },
})
</script>

<template>
  <a-card>
    <h4 class="mb-5">
      {{ $t('product.photo_video') }}
    </h4>

    <section class="mb-5">
      <div class="font-weight-semibold">
        {{ $t('product.photo_title') }}
      </div>
      <div class="text-muted mb-4">
        {{ $t('product.foto_video_description', { minWidth, minHeight }) }}
      </div>

      <div class="image-wrapper">
        <div
          v-for="(image, index) in activeImages"
          :key="`${image}_${index}`"
        >
          <a-popover
            placement="bottom"
            :trigger="permission.includes('WRITE') ? 'hover' : 'contextmenu'"
          >
            <template #content>
              <div style="width: 150px">
                <a-button
                  v-if="index !== 0 && !image.failed"
                  type="primary"
                  size="small"
                  shape="round"
                  class="mb-2 w-100 text-small"
                  @click="setAsPrimary(index)"
                >
                  Set Utama
                </a-button>
                <a-button
                  v-if="index !== 1 && !image.failed"
                  ghost
                  type="primary"
                  size="small"
                  shape="round"
                  class="mb-2 w-100"
                  @click="setAsThumbnail(index)"
                >
                  Set Thumbnail
                </a-button>
                <a-popover
                  v-if="selectedImages.includes(image.ref_id)"
                  title=""
                  trigger="hover"
                >
                  <template #content>
                    <p>Cant remove this image. This image is used in another product.</p>
                  </template>
                  <a-button class="w-100" icon="delete" size="small" shape="round" />
                </a-popover>
                <a-popconfirm
                  v-else
                  title="Are you sure want to remove this image ?"
                  ok-text="Remove"
                  cancel-text="Cancel"
                  @confirm="deleteImage(index)"
                >
                  <a-button class="w-100" icon="delete" size="small" shape="round" />
                </a-popconfirm>
              </div>
            </template>
            <div class="image-item">
              <img :src="getImageUrl(image)" />

              <div v-if="image.uploading" class="loading">
                <a-icon type="loading" spin />
              </div>

              <a-popover trigger="hover" placement="top">
                <template #content>
                  <div class="failed-reason" style="font-size: 0.75rem; max-width: 350px">
                    {{ image.failed }}
                  </div>
                </template>
                <div v-if="image.failed" class="failed" @click="uploadImages()">
                  <a-icon type="exclamation-circle" />
                  <div>Failed</div>
                </div>
              </a-popover>

              <div v-if="index === 0" class="image-label">
                Utama
              </div>
              <div v-if="index === 1" class="image-label">
                Thumbnail
              </div>
            </div>
          </a-popover>
        </div>
        <div v-if="isCanAddImage">
          <label class="image-item uploader">
            <input
              type="file"
              class="d-none"
              multiple
              :accept="ACCEPTED_EXTENSION.map(e => `.${e}`).join(',')"
              @change="onFileChanged"
            />
            <div>
              <a-icon type="plus" />
              <div>Gambar</div>
            </div>
          </label>
        </div>
      </div>

      <ValidationProvider
        v-slot="{ errors }"
        tag="div"
        name="Foto"
        rules="required"
        :custom-messages="{
          required: 'Upload minimal 1 gambar produk',
        }"
      >
        <input type="hidden" :value="activeImages" />
        <div v-if="errors.length" class="ant-form-explain text-danger">
          {{ errors[0] }}
        </div>
      </ValidationProvider>
    </section>

    <!-- <section>
      <div class="font-weight-semibold">
        {{ $t('product.video_title') }}
      </div>
      <div class="text-muted mb-3">
        {{ $t('product.video_description') }}
      </div>

      <a-input
        v-model="model.video_url"
        placeholder="https://www.youtube.com/watch?v=..."
        class="h-48px"
      />
    </section> -->
  </a-card>
</template>

<style scoped lang="scss">
.image-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-left: -.75rem;
  margin-right: -.75rem;

  > * {
    padding: 0 .75rem;
  }

  .image-item {
    position: relative;
    border: 1px solid #ccc;
    border-radius: .5rem;
    overflow: hidden;
    width: 150px;
    height: 150px;
    margin-bottom: 1.5rem;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
    

    &.uploader {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ccc;
      cursor: pointer;
      border: 2px dashed #ddd;

      &:hover {
        background-color: #fafafa;
      }

      .anticon {
        font-size: 2rem;
        display: inline-block;
        margin-bottom: .5rem;
      }
    }

    .image-label {
      padding: .15rem .65rem;
      background: var(--kit-color-primary);
      color: white;
      font-size: .8rem;
      position: absolute;
      bottom: 0;
      border-radius: 0 .5rem 0 0;
    }

    .loading,
    .failed {
      position: absolute;
      inset: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, .2);
      
      .anticon {
        font-size: 3rem;
        color: var(--kit-color-primary);
      }
    }

    .failed {
      background-color: rgba(255, 255, 255, .9);
      .anticon {
        font-size: 2rem;
        margin-bottom: .5rem;
      }
    }
  }
}
</style>
