<script>
import { defineComponent, ref, computed, watch, getCurrentInstance } from 'vue' // reactive
import FormItem from '@/components/Input/FormItem.vue'
import DetailCatalogMultiVariant from './DetailCatalogMultiVariant.vue'
import isEqual from 'lodash/isEqual'

const DEFAULT_VARIANT_TYPE_VALUE = {
  name: '',
  values: [],
}

const MAX_VARIANT = 3

const DEFAULT_CATALOG_VALUE = () => ({
  id: null,
  factory_sku: null,
  images: [],
  price: {
    currency: 'IDR',
    value: 0,
    min_qty: 1,
    price_qty: 1,
    uom: 'PCS',
    price_id: null,
    formula_id: null,
    product_variables: [],
  },
  sku: null,
  status: true,
  title: null,
  ext_catalog_id: null,
  option: null,
})

const buildVariants = (array) => {
  const results = []
  const max = array.length - 1
  
  const generate = (index = 0, prefix = []) => {
    if (!array[index]) return

    for (let j = 0, l = array[index].length; j < l; j++) {
      const temp = [...prefix]
      temp.push(array[index][j])
      if (index === max) {
        results.push(temp)
      } else {
        generate(index + 1, temp)
      }
    }
  }
  
  generate()
  
  return results
}

/**
 * @template T
 * @typedef {import('@vue/composition-api').Ref<T>} Ref
 */
/**
 * @typedef {import('@/types/product').ProductFormModel} ProductFormModel
 */
export default defineComponent({
  components: {
    FormItem,
    DetailCatalogMultiVariant,
},
  props: {
    /** @type {ProductFormModel} */
    value: {
      type: Object,
      required: true,
    },
    business: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit } ) {
    const { $modal } = getCurrentInstance().proxy.$root
    
    const defaultVariantFromProps = (props.value?.catalogs?.options || []).map(key => ({
      name: key,
      values: props.value?.catalogs?.attributes[key],
    }))
    const variantTypes = ref(
      defaultVariantFromProps.length ? defaultVariantFromProps : [{...DEFAULT_VARIANT_TYPE_VALUE}],
    )
    const tempVariant = ref(
      defaultVariantFromProps.length ? defaultVariantFromProps : [{...DEFAULT_VARIANT_TYPE_VALUE}],
    )
    watch(() => [props.value?.catalogs?.options, props.value?.catalogs?.attributes], (newValue, old) => {
      const [options, attributes] = newValue
      const [oldOptions, oldAttributes] = old || []
      if (!isEqual(options, oldOptions) || !isEqual(attributes, oldAttributes)) {
        const newVariantTypes = (options || []).map(key => ({
          name: key,
          values: [...new Set(attributes[key])],
        })) 
        variantTypes.value = newVariantTypes.length ? newVariantTypes : [{ ...DEFAULT_VARIANT_TYPE_VALUE }]
      }
    }, { immediate: true })
    const options = computed(() => variantTypes.value.map((variantType) => variantType.name))
    const attributes = computed(() => variantTypes.value.reduce((acc, { values, name }) => ({ ...acc, [name]: values }), {}))
    const addVariantType = () => {
      if (!isReachMaxLimit.value) variantTypes.value.push({...DEFAULT_VARIANT_TYPE_VALUE})
    }
    const removeVariantType = (index) => {
      variantTypes.value.splice(index, 1)
    }
    const confirmRemoveVariantType = (index) => {
      $modal.confirm({
        icon: false,
        content: 'Jika dihapus, data varian yang telah dimasukkan akan berubah dan tipe varian akan hilang dari pilihan varian, lho.',
        onOk: () => {
          removeVariantType(index)
        },
      });
    }
    const changeVariant = (value, index) => {
      const mergeVariant = value.concat(tempVariant.value[index]?.values || [])
      variantTypes.value[index].values = [...new Set(mergeVariant)]
    }
    const isReachMaxLimit = computed(() => variantTypes.value.length >= MAX_VARIANT)
    const isVariantTypeValid = computed(() => variantTypes.value.length && variantTypes.value.every((variantType) => !!variantType.name && variantType.values.length > 0))

    /** @type {Ref<{ item: ProductFormModel['catalogs']['items'][0] }[]>} */
    const catalogues = ref([])
    watch(variantTypes, () => {
      if (isVariantTypeValid.value) {

        catalogues.value = buildVariants(variantTypes.value.map((variantType) => variantType.values) )
          .map((options) => {
            const option = variantTypes.value.reduce((previousValue, currentValue, currentIndex) => {
              previousValue[currentValue.name] = options[currentIndex]
              return previousValue
            }, {})

            const existingCatalogs = catalogues.value.length ? catalogues.value : (props.value.catalogs?.items || []).map(item => ({ item }))
            const exists = existingCatalogs.find(({ item }) => Object.entries(item.option || {}).every(([key, value]) => option[key] === value))

            const catalog = {
              ...(exists?.item || DEFAULT_CATALOG_VALUE()),
            }

            catalog.option = option
            catalog.title = [
                props.value.detail.title,
                (Object.values(option) || []).join(', '),
              ].filter(Boolean).join(' - ')

            catalog.price = { ...catalog.price }
            catalog.loading = false

            return { item: catalog }
          })

      }
    }, { deep: true, immediate: true })

    watch(() => props.value.catalogs?.items, (value) => {
      catalogues.value = [...catalogues.value].map(({ item }) => {
        const catalog = item.id ? value.find(({ id }) => id === item.id) : null

        if (catalog) {
          item.price = catalog.price
        } else if (!item.price) {
          item.price = DEFAULT_CATALOG_VALUE().price
        }

        return { item }
      })
    }, { deep: true })

    watch(catalogues, (value, old) => {
      if (!isEqual(value, old)) {
        emitValue()
      }
    }, { deep: true })

    const emitValue = () => {
      emit('input', {
        ...props.value,
        catalogs: {
          attributes: attributes,
          options: options,
          items: catalogues.value.map(c => c.item),
        },
      })
    }

    return {
      variantTypes,
      addVariantType,
      removeVariantType,
      confirmRemoveVariantType,
      isReachMaxLimit,
      isVariantTypeValid,
      catalogues,
      emitValue,
      changeVariant,
    }
  },
})
</script>

<template>
  <div class="product-variation">
    <section class="variant-type mb-5">
      <div v-for="(variantType, index) in variantTypes" :key="index" class="">
        <a-row :gutter="16" type="flex">
          <a-col :xs="{ span: 24 }" :md="{ span: 8 }" :lg="{ span: 6 }" :xl="{ span: 5 }">
            <FormItem tag="div" :label="`Nama Varian ${index + 1}`" rules="required|max:20">
              <a-input
                v-model="variantType.name"
                class="h-48px w-100 variant-type-name-input"
                :max-length="20"
                :suffix="variantType.name.length + '/20'"
                :disabled="!!$route.query.id"
                @blur="(e) => {
                  if (variantTypes.map((variantType, i) => i === index ? undefined : variantType.name.toLowerCase()).includes(variantType.name.toLowerCase())) {
                    variantType.name = ''
                    e.target.focus()
                  }
                }"
              />
            </FormItem>
          </a-col>
          <a-col :xs="{ span: 24 }" :md="{ span: 16 }" :lg="{ span: 18 }" :xl="{ span: 19 }">
            <FormItem tag="div" :name="`Nama Varian ${index + 1}`" class="variant-options" rules="required">
              <a-select
                v-model="variantType.values"
                mode="tags"
                size="large"
                :placeholder="`Ketik pilihan ${variantType.name || `Varian ${index + 1}`}`"
                style="width: 100%"
                @change="changeVariant($event, index)"
              />
            </FormItem>
          </a-col>
        </a-row>
        <div v-if="!$route.query.id && variantTypes.length > 1" class="variant-type--actions">
          <a-button
            type="link"
            icon="delete"
            size="large"
            @click="confirmRemoveVariantType(index)"
          />
        </div>
      </div>

      <a-button
        v-if="!$route.query.id && !isReachMaxLimit"
        type="primary"
        icon="plus"
        ghost
        class="mt-3"
        @click="addVariantType"
      >
        Tambah varian
      </a-button>
    </section>

    <section class="variant-table">
      <div class="variant-table--wrapper">
        <template v-if="isVariantTypeValid">
          <div class="variant-table--header">
            <div v-for="(variantType, index) in variantTypes" :key="index" class="option-name">
              {{ variantType.name }}
            </div>
            <div class="ml-auto">
              SKU
            </div>
            <div class="actions">
              Status
            </div>
            <div class="caret" />
          </div>
          <DetailCatalogMultiVariant 
            v-for="(catalog, index) in catalogues"
            :key="`${index}-summary`"
            v-model="catalog.item"
            :data-catalog-id="catalog.item.id"
            :business="business"
            :images="value.images"
            :units="value.units"
            :product="value"
            :fix-unit="index !== 0"
            :sku-excludes-rule="catalogues.filter((_, i) => i !== index).map(i => i.item.sku)"
            @input="emitValue"
            @input:status="emitValue"
            @changeuom="val => $emit('changeuom', val)"
            @checksku="val => $emit('checksku', { sku: val.toUpperCase(), id: index })"
          />
        </template>
        <a-empty v-else>
          <template slot="description">
            <div class="font-weight-bold">
              Daftar varian belum lengkap diisi
            </div>
            <div>Lengkapi data varian di atas untuk mengisi detail varian</div>
          </template>
        </a-empty>
      </div>
    </section>
  </div>
</template>

<style lang="scss">
[data-kit-theme='default'] {
  .product-variation {
    .ant-select {
      font-size: 90%;
    }
    .ant-select-selection--multiple {
      > .ant-select-selection__rendered {
        line-height: 46px;

        li {
          height: 40px;
        } 

        .ant-select-selection__placeholder {
          color: #999;
        }

        .ant-select-selection__choice {
          display: flex;
          align-items: center;
          background: #e6e6e6;
          padding: 0;

          > * {
            height: 100%;
          }

          &__content {
            padding: 0 .5rem;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          &__remove {
            display: flex;
            position: relative;
            transform: none;
            align-items: center;
            justify-content: center;
            padding: 0 .25rem;
            margin-right: -.25rem;

            &:hover {
              background-color: rgba(224, 0, 0, 0.15);
              color: var(--kit-color-primary);
              cursor: pointer;
            }
          }
        }
      }
    }

    .variant-type {
      > div {
        display: flex;

        > .ant-row-flex {
          width: 100%;
        }
      }

      .ant-form-item {
        margin-bottom: .5rem;
      }

      .ant-form-explain {
        font-size: 85%;
        margin-top: .5rem;
      }

      .variant-type-name-input .ant-input-suffix {
        font-size: 80%;
        color: #999;
      }

      .ant-form-item-label {
        margin-bottom: -1.25rem;
        line-height: 1.5;
        z-index: 1;

        label {
          font-size: 80%;
          background: #fff;
          padding: 0 .25rem;
          margin-left: .5rem;
          color: #999;

          &:after {
            content: none;
          }
        }
      }

      &--actions {
        display: flex;
        justify-content: flex-end;
        margin-top: .5rem;
        padding: 0 0 0 1rem;
        width: 75px;

        > button {
          height: 48px;
          margin-top: .9rem;
        }
      }

    }

    .variant-options {
      margin-top: 1.4rem;
      font-size: 80%;
    }

    .variant-table {
      padding-top: 2rem;
      margin-top: -1rem;
      border-top: 1px dashed #ccc;
      
      &--header,
      &--summary {
        display: flex;
        align-items: center;
        border-radius: .25rem;

        > div {
          padding: .75rem 1.25rem;
          width: 200px;

          &.option-name {
            width: 150px;
          }

          &.actions {
            width: 100px;
            text-align: center;
          }
        }
      }

      &--header {
        font-size: 90%;
        color: #999;
        background-color: #fafafa;
        border: 1px solid #ccc;
        font-weight: bold;
        margin-bottom: 1rem;
      }

      .ant-collapse {
        background-color: transparent;
        margin-bottom: .75rem;
      }

      .ant-collapse-header {
        // border: 1px solid #ccc;
        background-color: transparent;
        padding: 0;
      }

      &--summary {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-width: 2px;
        z-index: 1;
        position: relative;
      }

      .ant-collapse-content-box {
        padding: 0;
        border-top: 0;
        background-color: #F2F4F8;
        padding: 1rem;
        margin-bottom: 0;
      }

      .caret {
        width: 50px;
      }
    }


    .collapsed {
      .variant-table {
        &--summary {
          border-bottom-left-radius: .25rem;
          border-bottom-right-radius: .25rem;
          border-bottom-width: 1px;
        }
      }
    }
  }
}
</style>
